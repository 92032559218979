import { ApiResponse, PaginationResult } from '@/types'
import downloadjs from 'downloadjs'
import authBridgeService from './auth-bridge-service'

export class BaseHttpService {

  baseUrl: string
  baseWsUrl: string

  constructor() {
    this.baseUrl = process.env.VUE_APP_API_HOST || 'http://localhost:3333'
    this.baseWsUrl = process.env.VUE_APP_WS_HOST || 'ws://localhost:3333'
  }

  protected handleError(err: any): never {
    console.log(err.message)
    throw err
  }

  protected async handleResponse<T>(response: Response): Promise<ApiResponse<T>> {
    const result: ApiResponse<T> = {
      success: true
    }
    if (response.status === 204) {
      result.response = undefined
      return result
    }
    const json = await response.json()
    if (response.status >= 400) {
      result.success = false
      json.status = response.status
      result.error = json
    } else {
      result.response = json
    }
    return result
  }

  protected async doApiGet<T>(uri: string): Promise<ApiResponse<T>> {
    return this.doApiCall('GET', uri)
  }

  protected async doApiPut<T>(uri: string, body?: string | Blob | ArrayBufferView | ArrayBuffer | FormData | URLSearchParams | ReadableStream<Uint8Array> | null | undefined): Promise<ApiResponse<T>> {
    return this.doApiCall('PUT', uri, body)
  }

  protected async doApiPost<T>(uri: string, body?: string | Blob | ArrayBufferView | ArrayBuffer | FormData | URLSearchParams | ReadableStream<Uint8Array> | null | undefined): Promise<ApiResponse<T>> {
    return this.doApiCall('POST', uri, body)
  }

  protected async doApiDelete<T>(uri: string): Promise<ApiResponse<T>> {
    return this.doApiCall('DELETE', uri)
  }

  protected async doApiCall<T>(method: string, uri: string, body?: string | Blob | ArrayBufferView | ArrayBuffer | FormData | URLSearchParams | ReadableStream<Uint8Array> | null | undefined): Promise<ApiResponse<T>> {
    console.log('api', method, 'call', uri)
    // const isAuthenticated = await this.isAuthenticated()
    // if (! isAuthenticated) {
    //   return { success: false, error: { code: 'unauthorized', message: 'unathorized', status: 401 }}
    // } else if (! ApiService.tenant) {
    //   return { success: false, error: { code: 'no-tenant', message: 'no tenant is specified', status: 400 }}
    // }
    const headers = new Headers()
    if (authBridgeService.$auth.initialized && authBridgeService.$auth.isAuthenticated) {
      const token = await authBridgeService.$auth.getTokenSilently({})
      if (token) {
        headers.append('Authorization', token)
      }
    }
    headers.append('Content-type', 'application/json')
    const url = `${this.baseUrl}${uri}`
    console.log('calling', url)
    const request = new Request(url, {
      method: method,
      headers,
      body
    })
    const response = await fetch(request)
    return await this.handleResponse(response)
  }

  protected async doFileUpload<T>(method: string, uri: string, body?: string | Blob | ArrayBufferView | ArrayBuffer | FormData | URLSearchParams | ReadableStream<Uint8Array> | null | undefined): Promise<ApiResponse<T>> {
    console.log('api', method, 'call', uri)
    // const isAuthenticated = await this.isAuthenticated()
    // if (! isAuthenticated) {
    //   return { success: false, error: { code: 'unauthorized', message: 'unathorized', status: 401 }}
    // } else if (! ApiService.tenant) {
    //   return { success: false, error: { code: 'no-tenant', message: 'no tenant is specified', status: 400 }}
    // }
    const headers = new Headers()
    // headers.append('Authorization', ApiService.accessToken || '')
    const request = new Request(`${process.env.VUE_APP_API_HOST}${uri}`, {
      method: method,
      headers,
      body
    })
    const response = await fetch(request)
    return await this.handleResponse(response)
  }

  public async download(uri: string, mimeType?: string): Promise<ApiResponse<boolean>> {
    // const isAuthenticated = await this.isAuthenticated()
    // if (! isAuthenticated) {
    //   return { success: false, error: { code: 'unauthorized', message: 'unathorized', status: 401 }}
    // } else if (! ApiService.tenant) {
    //   return { success: false, error: { code: 'no-tenant', message: 'no tenant is specified', status: 400 }}
    // }
    const headers = new Headers()
    // headers.append('Authorization', ApiService.accessToken || '')
    headers.append('Content-type', mimeType || 'application/json')
    const request = new Request(`${process.env.VUE_APP_API_HOST}${uri}`, {
      method: 'GET',
      headers,
    })
    const response = await fetch(request)
    if (response.status >= 400) {
      return {
        success: false,
        error: {
          code: '',
          message: await response.text(),
          status: response.status
        }
      }
    } else {
      const csv = await response.text()
      const disposition = response.headers.get('content-disposition')
      const filename = disposition ? disposition.split('=')[1].replace(/"/g, '') : 'download.csv'
      downloadjs(csv, filename, response.headers.get('content-type')?.split(';')[0])
      return {
        success: true,
      }
    }
  }

  public async downloadBinary(uri: string, mimeType: string): Promise<ApiResponse<boolean>> {
    // const isAuthenticated = await this.isAuthenticated()
    // if (! isAuthenticated) {
    //   return { success: false, error: { code: 'unauthorized', message: 'unathorized', status: 401 }}
    // } else if (! ApiService.tenant) {
    //   return { success: false, error: { code: 'no-tenant', message: 'no tenant is specified', status: 400 }}
    // }
    const headers = new Headers()
    // headers.append('Authorization', ApiService.accessToken || '')
    headers.append('Content-type', mimeType)
    const request = new Request(`${process.env.VUE_APP_API_HOST}${uri}`, {
      method: 'GET',
      headers,
    })
    const response = await fetch(request)
    if (response.status >= 400) {
      return {
        success: false,
        error: {
          code: '',
          message: await response.text(),
          status: response.status
        }
      }
    } else {
      const buffer = await response.arrayBuffer()
      const blob = new Blob([buffer], { type: mimeType})
      const disposition = response.headers.get('content-disposition')
      const filename = disposition ? disposition.split('=')[1].replace(/"/g, '') : 'download.csv'
      downloadjs(blob, filename, response.headers.get('content-type')?.split(';')[0])
      return {
        success: true,
      }
    }
  }

  public getEmptyPaginationResult<T>(pageSize?: number): PaginationResult<T> {
    return {
      data: [],
      meta: {
        total: 0,
        totalPages: 1,
        perPage: pageSize || 100,
        currentPage: 1,
        lastPage: 1,
        firstPage: 1,
        firstPageUrl: null,
        lastPageUrl: null,
        nextPageUrl: null,
        previousPageUrl: null,
      },
    }
  }
}
