















































































import { Component, Ref, Vue, Watch} from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import AdminState from '@/store/modules/admin'
import AddProjectDialog from '@/components/admin/AddProjectDialog.vue'
import kebabCase from 'lodash/kebabCase'

@Component({
  name: 'Admin',
  components: {
    AddProjectDialog,
  },
})
export default class ProjectAdmin extends Vue {

  adminState: AdminState = getModule(AdminState)
  newProject = {}
  kebabFn = kebabCase
  selectedProjectId = 0

  @Ref('addProjectDialog')
  addProjectDialog!: AddProjectDialog

  isMounted = false

  async mounted(): Promise<void> {
    await this.refreshProjects()
    const query = new URLSearchParams(location.search)
    if (query.has('projectId')) {
      const projectId = Number(query.get('projectId'))
      if (!isNaN(projectId)) {
        this.adminState.selectProject(projectId)
      }
    }
    this.selectedProjectId = this.adminState.activeProjectId
    this.isMounted = true
  }

  @Watch('adminState.activeProjectId')
  private onProjectChanged() {
    if (this.isMounted) {
      this.selectedProjectId = this.adminState.activeProjectId
    }
  }

  protected async refreshProjects(): Promise<void> {
    await this.adminState.loadProjects()
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  protected onSelectProject(event: any): void {
    const id = Number(event.target.value)
    this.adminState.selectProject(id)
  }

  protected async addProject(): Promise<void> {
    this.addProjectDialog.show()
  }
}
