var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex justify-content-start editable-row-header" },
    [
      _c("div", { staticClass: "btn-placeholder me-1" }),
      _c("div", { staticClass: "row g-0 row-data" }, [_vm._t("default")], 2),
      _c("div", { staticClass: "btn-placeholder ms-1" })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }