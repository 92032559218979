import { CompanyContract, ProjectContract, User } from '@/types/dtos'
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import store from '../index'
import adminDataService from '@/services/admin-data-service'
import UserService from '@/services/user-service'

@Module({
  name: 'admin',
  store,
  dynamic: true,
})
export default class AdminState extends VuexModule {

  projects: ProjectContract[] = []
  activeProjectIndex = -1
  loggedInUser: User|null = null
  projectsLoading = false
  activeCompany?: CompanyContract

  // == Logged In User Related ==
  @Mutation
  setLoggedInUser(user: User): void {
    this.loggedInUser = user
  }

  // == Project Related ==
  @Mutation
  selectProject(id: number): void {
    this.activeProjectIndex = this.projects.findIndex(project => project.id === id)
    sessionStorage.setItem('active-project-index', this.activeProjectIndex.toString())
  }

  @Mutation
  setProjectsLoading(val: boolean): void {
    this.projectsLoading = val
  }

  @Mutation
  setProjects(projects: ProjectContract[]): void {
    this.projects = projects
    const savedIndex = Number(sessionStorage.getItem('active-project-index'))
    if (!isNaN(savedIndex)) {
      this.activeProjectIndex = savedIndex
    }
  }

  @Action
  public async getLoggedInUser(userEmail: string): Promise<void> {
    try {
      const user = await UserService.getUserByEmail(userEmail)

      this.context.commit('setLoggedInUser', user)
    } catch (err: any) {
      console.error(err)
    }
  }

  @Action
  public async loadProjects(): Promise<void> {
    try {
      this.context.commit('setProjectsLoading', true)
      const projects = await adminDataService.getData<ProjectContract>('projects', 'projectName')
      this.context.commit('setProjects', projects)
    } catch (err: any) {
      console.error(err)
    } finally {
      this.context.commit('setProjectsLoading', false)
    }
  }

  get activeProject(): ProjectContract|null {
    if (this.activeProjectIndex >= 0 && this.projects.length > this.activeProjectIndex) {
      return this.projects[this.activeProjectIndex]
    }
    return null
  }

  get activeProjectId(): number {
    return this.activeProject?.id || 0
  }

  get activeProjectName(): string {
    return this.activeProject?.projectName || ''
  }

  get projectSelected(): boolean {
    return this.activeProjectIndex >= 0 && this.projects.length > 0
  }

  get projectSearchParams(): URLSearchParams {
    const params = new URLSearchParams()
    params.append('project_id', this.activeProjectId.toString())
    return params
  }

  get activeCompanyName(): string {
    return this.activeCompany?.companyName || 'Unknown Company'
  }
}
