var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "project-admin" }, [
    _c(
      "section",
      { staticClass: "d-flex align-items-stretch admin-section" },
      [
        _c("div", { staticClass: "flex-shrink-1 bpro-admin-menu" }, [
          _c("div", { staticClass: "d-flex flex-column" }, [
            _c("fieldset", [
              _c("legend", { staticClass: "bpro-menu-heading" }, [
                _vm._v("Active Project")
              ]),
              _c("div", { staticClass: "input-group" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-primary",
                    attrs: { type: "button" },
                    on: { click: _vm.addProject }
                  },
                  [_c("i", { staticClass: "bi-plus-lg" })]
                ),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedProjectId,
                        expression: "selectedProjectId"
                      }
                    ],
                    staticClass: "form-select form-select-sm",
                    attrs: { "aria-label": "Projects" },
                    on: {
                      change: [
                        function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.selectedProjectId = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        _vm.onSelectProject
                      ]
                    }
                  },
                  [
                    _c("option", { domProps: { value: -1 } }, [
                      _vm._v("Select a Project")
                    ]),
                    _vm._l(_vm.adminState.projects, function(project) {
                      return _c(
                        "option",
                        { key: project.id, domProps: { value: project.id } },
                        [_vm._v(_vm._s(project.projectName))]
                      )
                    })
                  ],
                  2
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-secondary",
                    attrs: { type: "button" },
                    on: { click: _vm.refreshProjects }
                  },
                  [
                    !_vm.adminState.projectsLoading
                      ? _c("i", { staticClass: "bi-arrow-clockwise" })
                      : _c(
                          "div",
                          {
                            staticClass: "spinner-border spinner-border-sm",
                            attrs: { role: "status" }
                          },
                          [
                            _c("span", { staticClass: "visually-hidden" }, [
                              _vm._v("Loading...")
                            ])
                          ]
                        )
                  ]
                )
              ])
            ]),
            _c(
              "div",
              { staticClass: "project-setup" },
              [
                _c(
                  "transition",
                  { attrs: { name: "fade", mode: "out-in" } },
                  [
                    _vm.adminState.projectSelected
                      ? _c(
                          "transition-group",
                          {
                            staticClass: "d-flex flex-column",
                            attrs: { name: "menu" }
                          },
                          [
                            _c(
                              "fieldset",
                              { key: "import-export-fs" },
                              [
                                _c(
                                  "legend",
                                  { staticClass: "bpro-menu-heading" },
                                  [
                                    _vm._v("Import / "),
                                    _c("i", [_vm._v("Export")])
                                  ]
                                ),
                                _c("p", { staticClass: "menu-heading" }, [
                                  _vm._v("System Data Import")
                                ]),
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: "/project-admin/import-export"
                                    }
                                  },
                                  [_vm._v("Flat File Imports")]
                                )
                              ],
                              1
                            ),
                            _c(
                              "fieldset",
                              { key: "project-setup-fs" },
                              [
                                _c(
                                  "legend",
                                  { staticClass: "bpro-menu-heading" },
                                  [
                                    _vm._v("Project Specific "),
                                    _c("i", [_vm._v("Setup")])
                                  ]
                                ),
                                _c("p", { staticClass: "menu-heading" }, [
                                  _vm._v("Users")
                                ]),
                                _c(
                                  "router-link",
                                  { attrs: { to: "/project-admin/users" } },
                                  [_vm._v("Users")]
                                ),
                                _c("p", { staticClass: "menu-heading" }, [
                                  _vm._v("Company Data")
                                ]),
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: "/project-admin/cost-center-types",
                                      "exact-path": ""
                                    }
                                  },
                                  [_vm._v("Cost Center Types")]
                                ),
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: "/project-admin/cost-centers",
                                      "exact-path": ""
                                    }
                                  },
                                  [_vm._v("Cost Centers")]
                                ),
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: "/project-admin/functional-areas",
                                      "exact-path": ""
                                    }
                                  },
                                  [_vm._v("Functional Areas")]
                                ),
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: "/project-admin/resources",
                                      "exact-path": ""
                                    }
                                  },
                                  [_vm._v("Resources")]
                                ),
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: "/project-admin/wbs-categories",
                                      "exact-path": ""
                                    }
                                  },
                                  [_vm._v("WBS Categories")]
                                ),
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: "/project-admin/projects",
                                      "exact-path": ""
                                    }
                                  },
                                  [_vm._v("Projects")]
                                ),
                                _c("p", { staticClass: "menu-heading" }, [
                                  _vm._v("Company Settings")
                                ]),
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: "/project-admin/resource-management",
                                      "exact-path": ""
                                    }
                                  },
                                  [_vm._v("Resource Management")]
                                ),
                                _c(
                                  "p",
                                  {
                                    key: "pd-heading",
                                    staticClass: "menu-heading"
                                  },
                                  [_vm._v("Project Data")]
                                ),
                                _c(
                                  "router-link",
                                  {
                                    key: "pr-link",
                                    attrs: {
                                      to: "/project-admin/project-roles",
                                      "exact-path": ""
                                    }
                                  },
                                  [_vm._v("Project Roles")]
                                ),
                                _c(
                                  "router-link",
                                  {
                                    key: "sow-type-link",
                                    attrs: {
                                      to: "/project-admin/sow-types",
                                      "exact-path": ""
                                    }
                                  },
                                  [_vm._v("SOW Types")]
                                ),
                                _c(
                                  "router-link",
                                  {
                                    key: "clin-type-link",
                                    attrs: {
                                      to: "/project-admin/clin-types",
                                      "exact-path": ""
                                    }
                                  },
                                  [_vm._v("CLIN Types")]
                                ),
                                _c(
                                  "router-link",
                                  {
                                    key: "op-link",
                                    attrs: {
                                      to: "/project-admin/option-periods",
                                      "exact-path": ""
                                    }
                                  },
                                  [_vm._v("Option Periods")]
                                ),
                                _c(
                                  "router-link",
                                  {
                                    key: "cdrl-link",
                                    attrs: {
                                      to: "/project-admin/cdrl",
                                      title:
                                        "Contract Data Requirement Lists (CDRL)",
                                      "exact-path": ""
                                    }
                                  },
                                  [_vm._v("CDRLs")]
                                ),
                                _c(
                                  "p",
                                  {
                                    key: "ps-heading",
                                    staticClass: "menu-heading"
                                  },
                                  [_vm._v("Project Settings")]
                                ),
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: "/project-admin/wbs-management",
                                      "exact-path": ""
                                    }
                                  },
                                  [_vm._v("WBS Management")]
                                ),
                                _c(
                                  "router-link",
                                  {
                                    key: "sow-link",
                                    attrs: {
                                      to: "/project-admin/statement-of-works",
                                      "exact-path": ""
                                    }
                                  },
                                  [_vm._v("SOW Management")]
                                ),
                                _c(
                                  "router-link",
                                  {
                                    key: "clin-link",
                                    attrs: {
                                      to: "/project-admin/clins",
                                      "exact-path": ""
                                    }
                                  },
                                  [_vm._v("CLIN Management")]
                                ),
                                _c(
                                  "router-link",
                                  {
                                    key: "sc-cw-link",
                                    attrs: {
                                      to: "/project-admin/sow-clin-crosswalk",
                                      "exact-path": ""
                                    }
                                  },
                                  [_vm._v("SOW / CLIN Crosswalk")]
                                )
                              ],
                              1
                            ),
                            _c(
                              "fieldset",
                              {
                                key: "project-execution-fs",
                                staticClass: "project-execution"
                              },
                              [
                                _c(
                                  "legend",
                                  { staticClass: "bpro-menu-heading" },
                                  [
                                    _vm._v("Project "),
                                    _c("i", [_vm._v("Execution")])
                                  ]
                                ),
                                _c(
                                  "router-link",
                                  {
                                    key: "scm-link",
                                    attrs: {
                                      to: "/project-admin/sow-claims-mapping",
                                      "exact-path": ""
                                    }
                                  },
                                  [_vm._v("SOW Claims & Mapping")]
                                ),
                                _c(
                                  "router-link",
                                  {
                                    key: "pe-link",
                                    attrs: {
                                      to: "/project-admin/project-estimation",
                                      "exact-path": ""
                                    }
                                  },
                                  [_vm._v("Project Estimation")]
                                )
                              ],
                              1
                            )
                          ]
                        )
                      : _c(
                          "p",
                          {
                            key: "else-content",
                            staticClass: "px-2 py-1 fst-italic rounded-1"
                          },
                          [_vm._v("No Project Selected")]
                        )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ]),
        _c(
          "div",
          { staticClass: "flex-grow-1 bpro-admin-content" },
          [_c("router-view")],
          1
        ),
        _c("add-project-dialog", {
          ref: "addProjectDialog",
          on: { addProjectSaved: _vm.refreshProjects }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }