import { ToastData } from '@/types'
import { Module, VuexModule, Mutation } from 'vuex-module-decorators'
import store from '../index'
import { v4 as uuidv4 } from 'uuid'

@Module({
  name: 'toast',
  store,
  dynamic: true,
})
export default class ToastState extends VuexModule {

  toasts: ToastData[] = []

  @Mutation
  removeToast(id: string): void {
    const index = this.toasts.findIndex(t => t.id === id)
    this.toasts.splice(index, 1)
  }

  @Mutation
  showToast(payload: string | { content: string; delay?: number }, type: 'success'|'danger'|'info'): void {
    const content = typeof payload === 'string' ? payload : payload.content
    const delay = typeof payload === 'string' ? undefined : payload.delay
    this.toasts.push({
      id: uuidv4(),
      content,
      type,
      delay,
    })
  }

  @Mutation
  showInfo(payload: string | { content: string; delay?: number }): void {
    const content = typeof payload === 'string' ? payload : payload.content
    const delay = typeof payload === 'string' ? undefined : payload.delay
    this.toasts.push({
      id: uuidv4(),
      content: content,
      type: 'info',
      delay: delay,
    })
  }

  @Mutation
  showSuccess(payload: string | { content: string; delay?: number }): void {
    const content = typeof payload === 'string' ? payload : payload.content
    const delay = typeof payload === 'string' ? undefined : payload.delay
    this.toasts.push({
      id: uuidv4(),
      content: content,
      type: 'success',
      delay: delay,
    })
  }

  @Mutation
  showError(payload: string | { content: string; delay?: number }): void {
    const content = typeof payload === 'string' ? payload : payload.content
    const delay = typeof payload === 'string' ? undefined : payload.delay
    this.toasts.push({
      id: uuidv4(),
      content: content,
      type: 'danger',
      delay: delay,
    })
  }
}