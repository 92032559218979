




















import { Component, Prop, Vue } from 'vue-property-decorator'
import { Modal } from 'bootstrap'
import { v4 as uuidv4 } from 'uuid'

@Component
export default class ConfirmModal extends Vue {

  @Prop({ default: '' })
  public header!: string

  @Prop({ default: 'Are you sure?' })
  public message!: string

  @Prop({ default: 'Cancel' })
  public cancelText!: string

  @Prop({ default: 'Yes' })
  public confirmText!: string

  private id: string = uuidv4()

  private resultFn?: (result: boolean) => void
  private modal?: Modal

  public async show(): Promise<boolean|undefined> {
    if (!this.resultFn) {
      const promise = new Promise<boolean>((resolve) => {
        const el = document.getElementById(this.id)
        el?.addEventListener('hidden.bs.modal', () => {
          if (this.resultFn) {
            this.resultFn(false)
            delete this.resultFn
            this.modal?.dispose()
            delete this.modal
          }
        })
        this.modal = new Modal(el!)
        this.resultFn = resolve
        this.modal.show()
      })
      return promise
    }
    return
  }

  private confirm(): void {
    console.log('confirm', this.resultFn, 'this is', this)
    if (this.resultFn) {
      this.resultFn(true)
      this.modal?.hide()
      this.modal?.dispose()
      delete this.resultFn
      delete this.modal
    }
  }
}
