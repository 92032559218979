var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c(
        "nav",
        {
          staticClass: "navbar navbar-expand-md navbar-light",
          staticStyle: { height: "70px" }
        },
        [
          _c("div", { staticClass: "container-fluid" }, [
            _vm._m(0),
            _vm._m(1),
            _c(
              "div",
              {
                staticClass: "collapse navbar-collapse",
                attrs: { id: "navbarSupportedContent" }
              },
              [
                _c("div", { staticClass: "flex-grow-1" }, [
                  _vm.$route.name && _vm.$route.name !== "Home"
                    ? _c("span", {
                        staticClass: "align-self-end bpro-route",
                        domProps: { innerHTML: _vm._s(_vm.$route.name) }
                      })
                    : _vm._e()
                ]),
                _c(
                  "ul",
                  { staticClass: "navbar-nav align-items-center" },
                  [
                    _c(
                      "li",
                      { staticClass: "nav-item" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "nav-link",
                            attrs: { to: "/company-admin" }
                          },
                          [_vm._v("Company Admin")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "li",
                      { staticClass: "nav-item" },
                      [
                        _c(
                          "router-link",
                          { staticClass: "nav-link", attrs: { to: "/" } },
                          [_vm._v("Projects")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "li",
                      { staticClass: "nav-item" },
                      [
                        _c(
                          "router-link",
                          { staticClass: "nav-link", attrs: { to: "/about" } },
                          [_vm._v("About")]
                        )
                      ],
                      1
                    ),
                    _c("company-menu"),
                    _c("auth-display")
                  ],
                  1
                )
              ]
            )
          ])
        ]
      ),
      _c("router-view"),
      _c("toast-display")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "navbar-brand d-flex align-items-center",
        attrs: { href: "#" }
      },
      [
        _c("img", {
          attrs: { alt: "logo", src: require("./assets/logo.svg") }
        }),
        _c("p", { staticClass: "logo d-inline" }, [
          _vm._v("BOE"),
          _c("i", [_vm._v("Pro")]),
          _vm._v("™")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "navbar-toggler",
        attrs: {
          type: "button",
          "data-bs-toggle": "collapse",
          "data-bs-target": "#navbarSupportedContent",
          "aria-controls": "navbarSupportedContent",
          "aria-expanded": "false",
          "aria-label": "Toggle navigation"
        }
      },
      [_c("span", { staticClass: "navbar-toggler-icon" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }