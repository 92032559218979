var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "toast",
        "align-items-center",
        "border-0",
        "text-white",
        {
          "bg-success": _vm.data.type === "success",
          "bg-danger": _vm.data.type === "danger",
          "bg-info": _vm.data.type === "info"
        }
      ],
      attrs: {
        id: _vm.data.id,
        role: "alert",
        "aria-live": "assertive",
        "aria-atomic": "true",
        "data-bs-delay": _vm.data.delay || 5000
      }
    },
    [
      _c("div", { staticClass: "d-flex" }, [
        _c("div", { staticClass: "toast-body" }, [
          _vm._v(" " + _vm._s(_vm.data.content) + " ")
        ]),
        _c("button", {
          staticClass: "btn-close btn-close-white me-2 m-auto",
          attrs: {
            type: "button",
            "data-bs-dismiss": "toast",
            "aria-label": "Close"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }