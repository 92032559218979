var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.$auth.loading,
          expression: "!$auth.loading"
        }
      ],
      staticClass: "nav-item dropdown auth-display"
    },
    [
      !_vm.$auth.isAuthenticated
        ? _c(
            "a",
            {
              staticClass: "nav-link",
              attrs: { href: "#" },
              on: { click: _vm.login }
            },
            [
              _vm._v("Log in "),
              _c("i", { staticClass: "bi-box-arrow-in-right ms-1" })
            ]
          )
        : _c(
            "a",
            {
              staticClass: "nav-link dropdown-toggle",
              attrs: {
                href: "#",
                id: "navbarDropdown",
                role: "button",
                "data-bs-toggle": "dropdown",
                "aria-expanded": "false"
              }
            },
            [
              _vm.$auth.user
                ? [
                    _vm.$auth.user.picture
                      ? _c("img", {
                          staticClass: "profile",
                          attrs: { src: _vm.$auth.user.picture }
                        })
                      : _c("span", [_vm._v(_vm._s(_vm.$auth.user.email))])
                  ]
                : _vm._e()
            ],
            2
          ),
      _vm.$auth.user
        ? _c(
            "ul",
            {
              staticClass: "dropdown-menu dropdown-menu-end",
              attrs: { "aria-labelledby": "navbarDropdown" }
            },
            [
              _c("li", [
                _c("p", { staticClass: "dropdown-header" }, [
                  _vm._v(_vm._s(_vm.$auth.user.email))
                ])
              ]),
              _vm._m(0),
              _vm._m(1),
              _c("li", [
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item d-flex justify-content-between",
                    attrs: { href: "#", title: "Log out" },
                    on: { click: _vm.logout }
                  },
                  [
                    _c("span", [_vm._v("Log out")]),
                    _c("i", { staticClass: "bi-box-arrow-right" })
                  ]
                )
              ])
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("a", { staticClass: "dropdown-item disabled", attrs: { href: "#" } }, [
        _vm._v("My Account")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [_c("hr", { staticClass: "dropdown-divider" })])
  }
]
render._withStripped = true

export { render, staticRenderFns }