import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import ProjectAdmin from '../views/ProjectAdmin.vue'
import CompanyAdmin from '../views/CompanyAdmin.vue'
import { authGuard } from '@/auth/auth-guard'
import RowState from '@/store/modules/row'
import { getModule } from 'vuex-module-decorators'

Vue.use(VueRouter)

const breadcrumb = '&rsaquo;'
const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/company-admin',
    name: 'Company Admin',
    component: CompanyAdmin,
    children: [
      {
        path: 'import-export',
        name: `Company Admin ${breadcrumb} Import & Export`,
        props: { isProjectLevel: false },
        component: () => import(/* webpackChunkName: "admin" */ '../components/admin/ImportExport.vue'),
      },
      {
        path: 'cost-center-types',
        name: `Company Admin ${breadcrumb} Cost Center Types`,
        props: {
          isProjectLevel: false
        },
        component: () => import(/* webpackChunkName: "admin" */ '../components/admin/company/CostCenterTypes.vue'),
      },
      {
        path: 'cost-centers',
        name: `Company Admin ${breadcrumb} Cost Centers`,
        props: {
          isProjectLevel: false,
        },
        component: () => import(/* webpackChunkName: "admin" */ '../components/admin/company/CostCenterManagement.vue'),
      },
      {
        path: 'functional-areas',
        name: `Company Admin ${breadcrumb} Functional Areas`,
        props: { isProjectLevel: false },
        component: () => import (/* webpackChunkName: "admin" */ '../components/admin/company/FunctionalAreas.vue'),
      },
      {
        path: 'resources',
        name: `Company Admin ${breadcrumb} Resources`,
        props: { isProjectLevel: false },
        component: () => import(/* webpackChunkName: "admin" */ '../components/admin/company/Resources.vue')
      },
      {
        path: 'wbs-categories',
        name: `Company Admin ${breadcrumb} WBS Categories`,
        props: { isProjectLevel: false },
        component: () => import(/* webpackChunkName: "admin" */ '../components/admin/company/WorkBreakdownStructureCategories.vue')
      },
      {
        path: 'cost-centers',
        name: `Company Admin ${breadcrumb} Cost Center Management`,
        props: { isProjectLevel: false },
        component: () => import(/* webpackChunkName: "admin" */ '../components/admin/company/CostCenterManagement.vue')
      },
      {
        path: 'resource-management',
        name: `Company Admin ${breadcrumb} Resource Management`,
        props: { isProjectLevel: false },
        component: () => import(/* webpackChunkName: "admin" */ '../components/admin/company/ResourceManagement.vue')
      },
    ],
  },
  {
    path: '/project-admin',
    name: 'Project Admin',
    component: ProjectAdmin,
    children: [
      {
        path: 'cost-center-types',
        name: `Project Admin ${breadcrumb} Cost Center Types`,
        props: { isProjectLevel: true },
        component: () => import(/* webpackChunkName: "admin" */ '../components/admin/company/CostCenterTypes.vue'),
      },
      {
        path: 'functional-areas',
        name: `Project Admin ${breadcrumb} Functional Areas`,
        props: { isProjectLevel: true },
        component: () => import(/* webpackChunkName: "admin" */ '../components/admin/company/FunctionalAreas.vue')
      },
      {
        path: 'resources',
        name: `Project Admin ${breadcrumb} Resources`,
        props: { isProjectLevel: true },
        component: () => import(/* webpackChunkName: "admin" */ '../components/admin/company/Resources.vue')
      },
      {
        path: 'wbs-categories',
        name: `Project Admin ${breadcrumb} WBS Categories`,
        props: { isProjectLevel: true },
        component: () => import(/* webpackChunkName: "admin" */ '../components/admin/company/WorkBreakdownStructureCategories.vue')
      },
      {
        path: 'import-export',
        name: `Project Admin ${breadcrumb} Import & Export`,
        props: { isProjectLevel: true },
        component: () => import(/* webpackChunkName: "admin" */ '../components/admin/ImportExport.vue')
      },
      {
        path: 'cost-centers',
        name: `Project Admin ${breadcrumb} Cost Center Management`,
        props: { isProjectLevel: true },
        component: () => import(/* webpackChunkName: "admin" */ '../components/admin/company/CostCenterManagement.vue')
      },
      {
        path: 'project-roles',
        name: `Project Admin ${breadcrumb} Project Roles`,
        component: () => import(/* webpackChunkName: "admin" */ '../components/admin/projects/ProjectRoles.vue')
      },
      {
        path: 'users',
        name: `Project Admin ${breadcrumb} Users`,
        component: () => import(/* webpackChunkName: "admin" */ '../components/admin/company/Users.vue')
      },
      {
        path: 'resource-management',
        name: `Project Admin ${breadcrumb} Resource Management`,
        props: { isProjectLevel: true },
        component: () => import(/* webpackChunkName: "admin" */ '../components/admin/company/ResourceManagement.vue')
      },
      {
        path: 'wbs-management',
        name: `Project Admin ${breadcrumb} WBS Management`,
        props: { isProjectLevel: true },
        component: () => import(/* webpackChunkName: "admin" */ '../components/admin/projects/WorkBreakdownStructureManagement.vue')
      },
      {
        path: 'projects',
        name: `Project Admin ${breadcrumb} Projects`,
        component: () => import(/* webpackChunkName: "admin" */ '../components/admin/projects/Projects.vue')
      },
      {
        path: 'option-periods',
        name: `Project Admin ${breadcrumb} Option Periods`,
        component: () => import(/* webpackChunkName: "admin" */ '../components/admin/projects/OptionPeriods.vue')
      },
      {
        path: 'clin-types',
        name: `Project Admin ${breadcrumb} CLIN Types`,
        component: () => import(/* webpackChunkName: "admin" */ '../components/admin/projects/ClinTypes.vue')
      },
      {
        path: 'clins',
        name: `Project Admin ${breadcrumb} CLIN Management`,
        component: () => import(/* webpackChunkName: "admin" */ '../components/admin/projects/ClinManagement.vue')
      },
      {
        path: 'sow-types',
        name: `Admin ${breadcrumb} SOW Types`,
        component: () => import(/* webpackChunkName: "admin" */ '../components/admin/projects/SowTypes.vue')
      },
      {
        path: 'statement-of-works',
        name: `Project Admin ${breadcrumb} SOWs`,
        component: () => import(/* webpackChunkName: "admin" */ '../components/admin/projects/StatementOfWorkManagement.vue')
      },
      {
        path: 'sow-clin-crosswalk',
        name: `Project Admin ${breadcrumb} SOW/CLIN Crosswalk`,
        component: () => import(/* webpackChunkName: "admin" */ '../components/admin/projects/SowClinCdrlCrosswalk.vue')
      },
      {
        path: 'cdrl',
        name: `Project Admin ${breadcrumb} CDRL`,
        component: () => import(/* webpackChunkName: "admin" */ '../components/admin/projects/ContractDataRequirements.vue')
      },
      {
        path: 'sow-claims-mapping',
        name: `Project Admin ${breadcrumb} SOW Claims & Mapping`,
        component: () => import(/* webpackChunkName: "admin" */ '../components/admin/projects/SowClaimsMapping.vue'),
      },
      {
        path: 'project-estimation',
        name: `Project Admin ${breadcrumb} Project Estimation`,
        component: () => import(/* webpackChunkName: "admin" */ '../components/admin/projects/ProjectEstimation.vue'),
      },
      {
        path: 'estimator-sow-reference',
        name: `Project Admin ${breadcrumb} Estimator SOW Reference`,
        component: () => import(/* webpackChunkName: "admin" */ '../views/admin/project/EstimatorSowReference.vue')
      },
      {
        path: 'wbs-management',
        name: `Company Admin ${breadcrumb} WBS Management`,
        props: { isProjectLevel: true },
        component: () => import(/* webpackChunkName: "admin" */ '../components/admin/projects/WorkBreakdownStructureManagement.vue')
      },
    ],
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

router.beforeEach(authGuard)

router.beforeEach(async (to, from, next) => {
  const rowState: RowState = getModule(RowState)

  if (rowState.isDirty) {
    rowState.setCanEdit(false)
    rowState.setRedirectRoute(to.path)
    rowState.setShowConfirmModal(true)
    return false
  } else {
    rowState.setCanEdit(true)
    next()
  }
})

export default router
