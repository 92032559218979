var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      ref: "editableRowForm",
      staticClass:
        "d-flex justify-content-start align-items-center placeholder-glow  editable-row",
      attrs: { novalidate: "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.save.apply(null, arguments)
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "edit-group me-1" },
        [
          _c("transition", { attrs: { name: "flip", mode: "out-in" } }, [
            !_vm.isEditing
              ? _c(
                  "button",
                  {
                    key: "edit",
                    staticClass: "btn btn-sm btn-primary",
                    attrs: {
                      disabled: !_vm.rowState.canEdit,
                      title: "Edit",
                      "data-bs-toggle": "tooltip",
                      type: "button"
                    },
                    on: {
                      click: function($event) {
                        return _vm.edit()
                      }
                    }
                  },
                  [_c("i", { staticClass: "bi-pencil-fill" })]
                )
              : _c(
                  "button",
                  {
                    key: "cancel",
                    staticClass: "btn btn-sm btn-secondary",
                    attrs: {
                      title: "Cancel",
                      "data-bs-toggle": "tooltip",
                      type: "button"
                    },
                    on: {
                      click: function($event) {
                        return _vm.cancelEdit()
                      }
                    }
                  },
                  [_c("i", { staticClass: "bi-x-lg" })]
                )
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "row g-1 row-data align-items-center" },
        [
          _vm._t(
            "default",
            function() {
              return [_vm._v("Empty Row")]
            },
            {
              isEditing: _vm.isEditing,
              setDirty: _vm.setDirty,
              data: _vm.editedData,
              getValidationError: _vm.getValidationError
            }
          )
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "edit-group ms-1" },
        [
          _c("transition", { attrs: { name: "flip", mode: "out-in" } }, [
            _vm.isEditing
              ? _c(
                  "button",
                  {
                    key: "save",
                    staticClass: "btn btn-sm btn-primary",
                    attrs: {
                      disabled: !_vm.rowState.isDirty,
                      title: "Save",
                      "data-bs-toggle": "tooltip",
                      type: "submit"
                    }
                  },
                  [
                    !_vm.isSaving
                      ? _c("i", { staticClass: "bi-save" })
                      : _c(
                          "div",
                          {
                            staticClass: "spinner-border spinner-border-sm",
                            attrs: { role: "status" }
                          },
                          [
                            _c("span", { staticClass: "visually-hidden" }, [
                              _vm._v("Saving...")
                            ])
                          ]
                        )
                  ]
                )
              : _c(
                  "button",
                  {
                    key: "delete",
                    staticClass: "btn btn-sm btn-danger",
                    attrs: {
                      disabled: !_vm.rowState.canEdit,
                      type: "button",
                      title: "Delete",
                      "data-bs-toggle": "tooltip"
                    },
                    on: { click: _vm.confirmDestroy }
                  },
                  [
                    !_vm.isDeleting
                      ? _c("i", { staticClass: "bi-trash-fill" })
                      : _c(
                          "div",
                          {
                            staticClass: "spinner-border spinner-border-sm",
                            attrs: { role: "status" }
                          },
                          [
                            _c("span", { staticClass: "visually-hidden" }, [
                              _vm._v("Deleting...")
                            ])
                          ]
                        )
                  ]
                )
          ])
        ],
        1
      ),
      _c("confirm-modal", {
        ref: "cancelModal",
        attrs: {
          header: "Confirm Cancel",
          cancelText: "No",
          message: _vm.confirmCancelMessage
        }
      }),
      _c("confirm-modal", {
        ref: "deleteModal",
        attrs: {
          header: "Confirm Delete",
          confirmText: "Delete",
          message: _vm.deleteConfirmationMessageText
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }