import { BaseHttpService } from './base-http-service'
import { User } from '@/types/dtos'

class UserService extends BaseHttpService {
    public async getUserByEmail(email: string): Promise<User> {
        const uri = `/api/users?email=${email}`
        const response = await this.doApiGet<User>(uri)
        if (response.success) {
            return response.response!
        }
        this.handleError(new Error(response.error?.message || 'Request failed but no message sent'))
    }
}

export default new UserService()
