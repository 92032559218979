import { Module, VuexModule } from 'vuex-module-decorators'
import { Mutation } from 'vuex-module-decorators'
import store from '../index'

@Module({
    name: 'row',
    store,
    dynamic: true,
})
export default class RowState extends VuexModule {
    isDirty = false
    showConfirmModal = false
    redirectRoute = ''
    canEdit = true

    @Mutation
    setIsDirty(val: boolean): void {
        this.isDirty = val
    }

    @Mutation
    setShowConfirmModal(val: boolean): void {
        this.showConfirmModal = val
    }

    @Mutation
    setRedirectRoute(val: string): void {
        this.redirectRoute = val
    }

    @Mutation
    setCanEdit(val: boolean): void {
        this.canEdit = val
    }
}
