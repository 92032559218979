

































import AdminState from '@/store/modules/admin'
import { Component, Vue } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({})
export default class CompanyAdmin extends Vue {
  adminState: AdminState = getModule(AdminState)
}
