<template>
  <div class="home">
    <projects uri="/"></projects>
  </div>
</template>

<script>
// @ is an alias to /src
import Projects from '@/components/admin/projects/Projects.vue'

export default {
  name: 'Home',
  components: {
    Projects
  }
}
</script>

<style lang="scss">
  .home {
    padding: 10px;
  }
</style>