import Vue from 'vue'
import App from './App.vue'
import store from '@/store'
import 'air-datepicker/air-datepicker.css'
import './styles/styles.scss'
import router from './router'
import './util/filters'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'

import { domain, clientId, audience } from '../auth_config.json'
import { Auth0Plugin } from './auth'

Vue.component('vue-typeahead-bootstrap', VueTypeaheadBootstrap)
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: (appState: any) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
