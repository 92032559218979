































import { Component, Vue } from 'vue-property-decorator'
import AuthDisplay from '@/components/util/AuthDisplay.vue'
import ToastDisplay from '@/components/ToastDisplay.vue'
import CompanyMenu from '@/components/CompanyMenu.vue'

@Component({
  components: {
    AuthDisplay,
    CompanyMenu,
    ToastDisplay,
  },
})
export default class App extends Vue {}
