




































import { EditableRowContract, ValidationError } from '@/types'
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import ConfirmModal from './ConfirmModal.vue'
import cloneDeep from 'lodash/cloneDeep'
import ToastState from '@/store/modules/toast'
import { getModule } from 'vuex-module-decorators'
import RowState from '@/store/modules/row'
import router from '@/router'

@Component({
  components: {
    ConfirmModal,
  }
})
export default class EditableRow extends Vue implements EditableRowContract {

  rowState: RowState = getModule(RowState)
  isEditing = false
  isSaving = false
  isDeleting = false
  editedData: any = {}
  validationErrors: ValidationError[] = []
  deleteConfirmationMessageText = ''

  @Prop({ default: 'You have unsaved changes.  Are you sure you want to cancel?' })
  public confirmCancelMessage!: string

  @Prop({ default: 'Are you sure you want to delete?' })
  public confirmDeleteMessage!: string | ((data: any) => string)

  @Prop({ required: true })
  public data!: any

  toastState: ToastState = getModule(ToastState)

  @Prop({ default: false })
  editOnStart!: boolean

  @Prop({ default: -1 })
  totalRows!: number

  @Ref('cancelModal')
  confirmCancelModal!: ConfirmModal

  @Ref('deleteModal')
  confirmDeleteModal!: ConfirmModal

  @Ref('editableRowForm')
  editForm!: HTMLFormElement

  mounted(): void {
    this.editedData = cloneDeep(this.data)
    if (this.editOnStart) {
      this.edit()
    }
  }

  public edit(): void {
    this.editedData = cloneDeep(this.data)
    this.isEditing = true
    this.$emit('modeChange', 'edit')
  }

  private async executeCancel(): Promise<void> {
    this.editedData = cloneDeep(this.data)
    this.isEditing = false
    this.rowState.setIsDirty(false)
    await this.$nextTick() // This is necessary to prevent a timing issue.
    this.$emit('cancel', { isNew: !this.data.id || this.data.isNew })
    this.$emit('modeChange', 'normal')
  }

  public async cancelEdit(): Promise<void> {
    if (this.rowState.isDirty) {
      const cancel = await this.confirmCancelModal.show()
      if (cancel) {
        this.rowState.setCanEdit(true)
        await this.executeCancel()
        if (this.rowState.redirectRoute) {
          const redirectRoute = this.rowState.redirectRoute
          this.rowState.setRedirectRoute('')
          await router.push(redirectRoute)
        }
      } else {
        this.rowState.setCanEdit(false)
        this.rowState.setRedirectRoute('')
      }
    } else {
      await this.executeCancel()
    }
  }

  public setDirty(dirty = true): void {
    this.rowState.setIsDirty(dirty)
    if (!this.editForm.checkValidity()) {
      this.editForm.classList.add('was-validated')
    }
  }

  public save(): void {
    const form = this.editForm
    if (form.checkValidity()) {
      form.classList.remove('was-validated')
      this.isSaving = true
      this.$emit('save', { data: this.editedData, component: this })
    } else {
      form.classList.add('was-validated')
    }
  }

  public actionComplete(actionName: string, errorMessage?: string, errors: ValidationError[] = []): void {
    this.isSaving = false
    this.isDeleting = false
    this.validationErrors = errors
    if (errorMessage) {
      this.toastState.showError(`${actionName} error: ${errorMessage}`)
    } else {
      this.isEditing = false
      this.$emit('modeChange', 'normal')
      this.toastState.showSuccess(`${actionName} successful`)
    }
  }

  public async confirmDestroy(): Promise<void> {
    if (typeof this.confirmDeleteMessage === 'string') {
      this.deleteConfirmationMessageText = this.confirmDeleteMessage
    } else if (typeof this.confirmDeleteMessage === 'function') {
      this.deleteConfirmationMessageText = this.confirmDeleteMessage(this.data)
    }
    const destroy = await this.confirmDeleteModal.show()
    if (destroy) {
      this.isDeleting = true
      this.$emit('delete', { data: this.editedData, component: this })
    }
  }

  getValidationError(field: string): ValidationError|undefined {
    return this.validationErrors.find(ve => ve.field === field)
  }

  @Watch('rowState.showConfirmModal')
  public async onShowConfirmModal(): Promise<void> {
    if (this.rowState.showConfirmModal) {
      this.rowState.setShowConfirmModal(false)
      await this.cancelEdit()
    }
  }
}
