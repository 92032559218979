import { ProjectContract, ProjectRoleContract } from '@/types/dtos'
import { BaseHttpService } from './base-http-service'

class ProjectService extends BaseHttpService {
  public async create(project: Partial<ProjectContract>): Promise<ProjectContract|undefined> {
    const uri = `/api/projects`
    const response = await this.doApiPost<ProjectContract>(uri, JSON.stringify(project))
    if (response.success) {
      return response.response
    }
    this.handleError(new Error(response.error?.message || 'Request failed but no message sent'))
  }

  public async swapSortOrder(itemA: ProjectContract, itemB: ProjectContract): Promise<boolean> {
    const uri = `/api/project-roles/${itemA.id}/swap-order/${itemB.id}`
    const response = await this.doApiPost(uri, '')
    if (response.success) {
      return response.success
    }
    this.handleError(new Error(response.error?.message || 'Request failed but no message sent'))
  }

  public async updateSortOrder(ids: number[]): Promise<ProjectRoleContract[]> {
    const uri = '/api/project-roles/sort-order'
    const response = await this.doApiPost<ProjectRoleContract[]>(uri, JSON.stringify({ ids }))
    if (response.success) {
      return response.response!
    }
    this.handleError(new Error(response.error?.message || 'Request failed but no message sent'))
  }
}

export default new ProjectService()
