import { ApiValidationErrors } from '@/types'

export default class ApiError extends Error {
  messages: ApiValidationErrors|undefined
  code?: string

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  constructor(messages?: ApiValidationErrors, code?: string, ...params: any) {
    super(...params)
    this.messages = messages
    this.code = code
  }
}