

























import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class AuthDisplay extends Vue {
  login(): void {
    this.$auth.loginWithRedirect()
  }

  logout(): void {
    this.$auth.logout({

    })
  }
}
