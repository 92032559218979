import authBridgeService from '@/services/auth-bridge-service'
import createAuth0Client, { Auth0Client, GetIdTokenClaimsOptions, GetTokenSilentlyOptions, IdToken, LogoutOptions, RedirectLoginOptions } from '@auth0/auth0-spa-js'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Auth0User } from './user'

export type Auth0Options = {
  domain: string;
  clientId: string;
  audience?: string;
  [key: string]: string | undefined;
}

export type RedirectCallback = (appState: any) => void

@Component
export class VueAuth extends Vue {
  initialized = false
  loading = true
  isAuthenticated = false
  user?: Auth0User
  auth0Client?: Auth0Client
  error?: Error

  async getUser(): Promise<Auth0User|undefined> {
    const rawUser = await this.auth0Client?.getUser()
    if (rawUser) {
      this.user = new Auth0User(rawUser)
    }
    return this.user
  }

  public loginWithRedirect(o: RedirectLoginOptions): any {
    return this.auth0Client?.loginWithRedirect(o)
  }

  /** Returns all the claims present in the ID token */
  getIdTokenClaims (o: GetIdTokenClaimsOptions): Promise<IdToken | undefined> {
    return this.auth0Client!.getIdTokenClaims(o)
  }

  /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
  getTokenSilently (o: GetTokenSilentlyOptions): Promise<string | undefined> {
    return this.auth0Client!.getTokenSilently(o)
  }

  /** Logs the user out and removes their session on the authorization server */
  logout (o: LogoutOptions): void | Promise<void> | undefined {
    return this.auth0Client?.logout(o)
  }

  public async init (onRedirectCallback: RedirectCallback, redirectUri: string, auth0Options: Auth0Options): Promise<void> {
    this.auth0Client = await createAuth0Client({
      domain: auth0Options.domain,
      client_id: auth0Options.clientId,
      redirect_uri: redirectUri,
      cacheLocation: 'memory',
    })

    try {
      if (
        window.location.search.includes("code=") &&
        window.location.search.includes("state=")
      ) {
        const { appState } = await this.auth0Client?.handleRedirectCallback()
        delete this.error
        onRedirectCallback(appState)
      }
    } catch (err: any) {
      this.error = err
    } finally {
      this.isAuthenticated = (await this.auth0Client?.isAuthenticated()) || false
      try {
        await this.sync()
      } catch (err: any) {
        console.error('vue-auth.init:', err)
      }
      this.loading = false
      this.initialized = true
    }
  }

  private async afterInit(): Promise<void> {
    // Add any additional things you need to do after login success in here.
    authBridgeService.$auth = this
  }

  public async sync(): Promise<void> {
    await this.getUser()
    try {
      await this.afterInit()
    } catch (err: any) {
      console.error('vue-auth.sync error:', err)
    }
  }
}