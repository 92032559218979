






import { Component, Vue } from 'vue-property-decorator'
import Toast from './Toast.vue'
import ToastState from '@/store/modules/toast'
import { getModule } from 'vuex-module-decorators'

@Component({
  components: {
    Toast,
  }
})
export default class ToastDisplay extends Vue {
  toastState: ToastState = getModule(ToastState)
}
