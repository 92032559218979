
import { VueConstructor } from 'vue'
import { Auth0Options, VueAuth } from './vue-auth'

const DEFAULT_REDIRECT_CALLBACK = () => {
  window.history.replaceState({}, document.title, window.location.pathname)
}

let instance: VueAuth

export const getInstance = (): VueAuth => instance

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.origin,
  ...options
}) => {
  if (instance) return instance

  instance = new VueAuth()
  instance.init(onRedirectCallback, redirectUri, options as Auth0Options)

  return instance
}

export const Auth0Plugin = {
  install(Vue: VueConstructor, options: Auth0Options): void {
    Vue.prototype.$auth = useAuth0(options)
  }
}
