var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "company-admin" }, [
    _c("section", { staticClass: "d-flex align-items-stretch admin-section" }, [
      _c("div", { staticClass: "flex-shrink-1 bpro-admin-menu" }, [
        _c("div", { staticClass: "d-flex flex-column" }, [
          _c(
            "fieldset",
            [
              _vm._m(0),
              _c("p", { staticClass: "menu-heading" }, [
                _vm._v("System Data Import")
              ]),
              _c(
                "router-link",
                { attrs: { to: "/company-admin/import-export" } },
                [_vm._v("Flat File Imports")]
              )
            ],
            1
          ),
          _c(
            "fieldset",
            [
              _vm._m(1),
              _c("p", { staticClass: "menu-heading" }, [
                _vm._v("Company Data")
              ]),
              _c(
                "router-link",
                { attrs: { to: "/company-admin/cost-center-types" } },
                [_vm._v("Cost Center Types")]
              ),
              _c(
                "router-link",
                { attrs: { to: "/company-admin/cost-centers" } },
                [_vm._v("Cost Centers")]
              ),
              _c(
                "router-link",
                { attrs: { to: "/company-admin/functional-areas" } },
                [_vm._v("Functional Areas")]
              ),
              _c("router-link", { attrs: { to: "/company-admin/resources" } }, [
                _vm._v("Resources")
              ]),
              _c(
                "router-link",
                { attrs: { to: "/company-admin/wbs-categories" } },
                [_vm._v("WBS Categories")]
              ),
              _c("p", { staticClass: "menu-heading" }, [
                _vm._v("Company Settings")
              ]),
              _c(
                "router-link",
                { attrs: { to: "/company-admin/resource-management" } },
                [_vm._v("Resource Management")]
              )
            ],
            1
          )
        ])
      ]),
      _c(
        "div",
        { staticClass: "flex-grow-1 bpro-admin-content" },
        [_c("router-view")],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", { staticClass: "bpro-menu-heading" }, [
      _vm._v("Import / "),
      _c("i", [_vm._v("Export")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", { staticClass: "bpro-menu-heading" }, [
      _vm._v("Company "),
      _c("i", [_vm._v("Setup")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }