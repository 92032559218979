
export class Auth0User {
  sub!: string
  names?: string
  nickname?: string
  picture?: string
  updatedAt?: string
  email!: string
  emailVerified?: boolean
  [key: string]: string | boolean | undefined | string[] | number

  provider?: string
  id?: string

  constructor(auth0User: { [key: string]: string | string[] | boolean | undefined }) {
    if (!auth0User) return
    for (const key in auth0User) {
      this[key] = auth0User[key]
    }

    this.sub = auth0User.sub as string
    this.provider = this.sub.split('|')[0]
    this.id = this.sub.split('|')[1]
  }
}