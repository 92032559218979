var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { class: _vm.dataModelUri }, [
    _c(
      "div",
      { staticClass: "d-flex justify-content-between align-items-center" },
      [
        _c("h4", [_vm._v(_vm._s(_vm.listTitle))]),
        _c("div", [
          _c(
            "button",
            {
              staticClass: "btn btn-sm btn-secondary",
              attrs: {
                type: "button",
                disabled: _vm.loading || _vm.numEditing > 0
              },
              on: {
                click: function($event) {
                  return _vm.refreshData(_vm.page)
                }
              }
            },
            [
              !_vm.loading
                ? _c("i", { staticClass: "bi-arrow-clockwise" })
                : _c(
                    "div",
                    {
                      staticClass: "spinner-border spinner-border-sm",
                      attrs: { role: "status" }
                    },
                    [
                      _c("span", { staticClass: "visually-hidden" }, [
                        _vm._v("Loading...")
                      ])
                    ]
                  ),
              _c("span", { staticClass: "ms-1" }, [_vm._v("Refresh")])
            ]
          ),
          _vm.showCreateButton
            ? _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-primary ms-1",
                  attrs: {
                    type: "button",
                    disabled: _vm.isCreating || _vm.numEditing > 0
                  },
                  on: { click: _vm.onAddNew }
                },
                [_c("i", { staticClass: "bi-plus-lg me-1" }), _vm._v("Create ")]
              )
            : _vm._e()
        ])
      ]
    ),
    _c(
      "div",
      {
        staticStyle: {
          height: "75vh",
          "overflow-y": "scroll",
          "overflow-x": "hidden"
        }
      },
      [
        _c(
          "section",
          { staticClass: "editable-row-container" },
          [
            _c("editable-row-header", [_vm._t("row-header-content")], 2),
            _c(
              "draggable",
              {
                attrs: {
                  disabled: !_vm.allowReordering || _vm.reorderDisabled,
                  handle: _vm.reorderHandle
                },
                on: { update: _vm.onDrag },
                model: {
                  value: _vm.pageData,
                  callback: function($$v) {
                    _vm.pageData = $$v
                  },
                  expression: "pageData"
                }
              },
              [
                _c(
                  "transition-group",
                  { attrs: { name: "list" } },
                  [
                    _vm._l(_vm.pageData, function(row, index) {
                      return _c("editable-row", {
                        key: row.id + "-" + row.updatedAt,
                        attrs: {
                          data: row,
                          editOnStart: !row.id,
                          totalRows: _vm.pageData ? _vm.pageData.length : 0,
                          confirmDeleteMessage: _vm.confirmDeleteMessage
                        },
                        on: {
                          save: _vm.onSave,
                          cancel: _vm.onCancel,
                          delete: _vm.onDelete,
                          modeChange: _vm.onModeChange
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(slotProps) {
                                return [
                                  _vm._t("row-content", null, {
                                    isEditing: slotProps.isEditing,
                                    setDirty: slotProps.setDirty,
                                    getValidationError:
                                      slotProps.getValidationError,
                                    data: slotProps.data,
                                    loading: _vm.loading,
                                    rowIndex: index,
                                    reorderDisabled: _vm.reorderDisabled,
                                    totalRows: _vm.pageData.length
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    }),
                    _vm.pageData.length === 0 && !_vm.loading
                      ? _c("div", { key: "no-data-row" }, [
                          _vm._v("No data available.")
                        ])
                      : _vm._e()
                  ],
                  2
                )
              ],
              1
            )
          ],
          1
        )
      ]
    ),
    _vm.pageMeta.total > 0
      ? _c(
          "nav",
          {
            staticClass: "mt-2 d-flex align-items-center flex-column",
            attrs: { "aria-label": "Data pagination" }
          },
          [
            _c(
              "ul",
              {
                staticClass: "pagination justify-content-center mb-0",
                staticStyle: { cursor: "pointer" }
              },
              [
                _c(
                  "li",
                  {
                    class: [
                      "page-item",
                      {
                        disabled: _vm.pageMeta.currentPage === 1 || _vm.loading
                      }
                    ]
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "page-link",
                        attrs: { "aria-label": "First Page" },
                        on: {
                          click: function($event) {
                            return _vm.refreshData(1)
                          }
                        }
                      },
                      [_c("i", { staticClass: "bi-chevron-double-left" })]
                    )
                  ]
                ),
                _c(
                  "li",
                  {
                    class: [
                      "page-item",
                      {
                        disabled: _vm.pageMeta.currentPage === 1 || _vm.loading
                      }
                    ]
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "page-link",
                        attrs: { "aria-label": "Previous" },
                        on: {
                          click: function($event) {
                            return _vm.refreshData(_vm.page - 1)
                          }
                        }
                      },
                      [_c("i", { staticClass: "bi-chevron-left" })]
                    )
                  ]
                ),
                _vm._l(_vm.prevPages, function(pg) {
                  return _c(
                    "li",
                    {
                      key: pg,
                      class: [
                        "page-item",
                        {
                          active: pg === _vm.pageMeta.currentPage,
                          disabled: _vm.loading
                        }
                      ]
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "page-link",
                          on: {
                            click: function($event) {
                              return _vm.refreshData(pg)
                            }
                          }
                        },
                        [_vm._v(_vm._s(pg))]
                      )
                    ]
                  )
                }),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.lazy.number",
                      value: _vm.page,
                      expression: "page",
                      modifiers: { lazy: true, number: true }
                    }
                  ],
                  staticClass: "form-control form-control-sm mx-1 current-page",
                  attrs: {
                    type: "number",
                    min: "1",
                    max: _vm.pageMeta.totalPages
                  },
                  domProps: { value: _vm.page },
                  on: {
                    change: [
                      function($event) {
                        _vm.page = _vm._n($event.target.value)
                      },
                      _vm.onPageChange
                    ],
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                }),
                _vm._l(_vm.nextPages, function(pg) {
                  return _c(
                    "li",
                    {
                      key: pg,
                      class: [
                        "page-item",
                        {
                          active: pg === _vm.pageMeta.currentPage,
                          disabled: _vm.loading
                        }
                      ]
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "page-link",
                          on: {
                            click: function($event) {
                              return _vm.refreshData(pg)
                            }
                          }
                        },
                        [_vm._v(_vm._s(pg))]
                      )
                    ]
                  )
                }),
                _c(
                  "li",
                  {
                    class: [
                      "page-item",
                      {
                        disabled:
                          _vm.pageMeta.currentPage === _vm.pageMeta.lastPage
                      }
                    ]
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "page-link",
                        attrs: { "aria-label": "Next" },
                        on: {
                          click: function($event) {
                            return _vm.refreshData(_vm.page + 1)
                          }
                        }
                      },
                      [_c("i", { staticClass: "bi-chevron-right" })]
                    )
                  ]
                ),
                _c(
                  "li",
                  {
                    class: [
                      "page-item",
                      {
                        disabled:
                          _vm.pageMeta.currentPage === _vm.pageMeta.lastPage ||
                          _vm.loading
                      }
                    ]
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "page-link",
                        attrs: { "aria-label": "Last Page" },
                        on: {
                          click: function($event) {
                            return _vm.refreshData(_vm.pageMeta.lastPage)
                          }
                        }
                      },
                      [_c("i", { staticClass: "bi-chevron-double-right" })]
                    )
                  ]
                )
              ],
              2
            ),
            _c("div", { staticClass: "total-pages" }, [
              _vm._v(
                _vm._s(_vm.pageMeta.totalPages) +
                  " " +
                  _vm._s(_vm.pageMeta.totalPages === 1 ? "page" : "pages")
              )
            ])
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }