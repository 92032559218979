








































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import adminDataService from '@/services/admin-data-service'
import EditableRow from '@/components/EditableRow.vue'
import EditableRowHeader from '@/components/EditableRowHeader.vue'
import Draggable from 'vuedraggable'
import { CrudSaveEvent, EditableListSearchParams, PaginationMetaData, PaginationResult } from '@/types'
import ToastState from '@/store/modules/toast'
import { getModule } from 'vuex-module-decorators'
import AdminState from '@/store/modules/admin'
import RowState from '@/store/modules/row'

@Component({
  components: {
    Draggable,
    EditableRow,
    EditableRowHeader,
  }
})
export default class EditableList extends Vue {

  adminState: AdminState = getModule(AdminState)
  rowState: RowState = getModule(RowState)
  pageData: any[] = []
  pageMeta: PaginationMetaData = adminDataService.getEmptyPaginationResult().meta
  page = 1
  loading = false
  listUri = ''
  numEditing = 0

  toastState: ToastState = getModule(ToastState)

  @Prop({ default: true })
  showCreateButton!: boolean

  @Prop({ required: true })
  dataModelUri!: string

  @Prop({ default: false })
  dataModelProjectBased!: boolean

  @Prop({ required: true })
  sortProperty!: string|string[]

  @Prop({ required: true })
  listTitle!: string

  @Prop({ default: () => [], type: Array })
  includeRelated!: string[]

  @Prop({ default: false })
  allowReordering!: boolean

  @Prop()
  reorderHandle: string|undefined

  @Prop({ default: () => { return {} }})
  searchParams!: EditableListSearchParams

  reorderDisabled = false

  /**
   * Uri allows you to override the default uri used when paginating.  Defaults to dataModelUri
   */
  @Prop({})
  uri?: string

  @Prop({ default: 100 })
  pageSize!: number

  @Prop({})
  save?: (event: CrudSaveEvent) => Promise<void>

  @Prop({})
  confirmDeleteMessage?: string | ((data: any) => string)

  get isCreating(): boolean {
    return this.pageData && this.pageData.length > 0 && this.pageData[0].id === 0
  }

  get prevPages(): number[] {
    const start = Math.max(1, this.page - 5)
    const end = Math.max(1, this.page - 1)
    const diff = end - start
    console.log('start', start, 'end', end, 'diff', diff)
    return this.page === start ? [] : Array(diff + 1).fill(0).map((_, index) => start + index)
  }

  get nextPages(): number[] {
    const start = Math.min(this.page + 1, this.pageMeta.totalPages)
    const end = Math.min(this.page + 5, this.pageMeta.totalPages)
    return this.page === end ? [] : Array(end - start + 1).fill(0).map((_, index) => start + index)
  }

  clampPageNumber(isMounting = false): void {
    if (this.page < 1) {
      this.page = 1
    } else if (!isMounting && this.page > this.pageMeta.totalPages) {
      this.page = this.pageMeta.totalPages || 1
    }
    console.log('clamped to page', this.page)
  }

  async onPageChange(): Promise<void> {
    this.clampPageNumber()
    await this.refreshData(this.page)
  }

  created(): void {
    this.listUri = this.uri || this.dataModelUri
  }

  async mounted(): Promise<void> {
    const query = new URLSearchParams(location.search)
    if (query.has('page')) {
      const pg = Number(query.get('page'))
      if (!isNaN(pg)) {
        this.page = pg
      }
      this.clampPageNumber(true)
    }
    console.log('mounted refreshing data page', this.page)
    await this.refreshData()
  }

  @Watch('searchParams')
  public async onSearchParamsUpdate(): Promise<void> {
    console.log('search params refreshing data', this.page)
    await this.refreshData()
  }

  @Watch('adminState.activeProjectId')
  public async onProjectChange(): Promise<void> {
    if (this.dataModelProjectBased) {
      await this.refreshData()
    }
  }

  public async refreshData(pageNum?: number): Promise<void> {
    try {
      if (this.loading || (this.dataModelProjectBased && !this.adminState.projectSelected)) {
        console.warn('skipping editable list refresh')
        return
      }
      const originalPage = this.page
      if (pageNum && pageNum !== this.page) {
        this.page = pageNum
        this.$router.replace({ path: `${this.listUri}`, query: { page: this.page.toString() }})
      }
      this.loading = true
      let search: URLSearchParams|undefined
      if (this.searchParams) {
        search = new URLSearchParams()
        for (const key of Object.keys(this.searchParams)) {
          search.set(key, this.searchParams[key].toString())
        }
      }
      let paginationResult: PaginationResult<any>
      if (this.dataModelProjectBased) {
        paginationResult = await adminDataService.getDataPage(this.dataModelUri, this.sortProperty, this.page, this.pageSize, search, this.includeRelated, this.adminState.activeProjectId)
      } else {
        paginationResult = await adminDataService.getDataPage(this.dataModelUri, this.sortProperty, this.page, this.pageSize, search, this.includeRelated)
      }
      this.pageData = paginationResult.data
      this.pageMeta = paginationResult.meta
      if (this.page > this.pageMeta.totalPages && this.pageMeta.totalPages > 0) {
        this.page = this.pageMeta.totalPages
        this.loading = false
        await this.refreshData(this.page)
      } else if (this.page < 1) {
        this.page = 1
      }
      if (this.page !== originalPage) {
        this.$router.replace({ path: `${this.listUri}`, query: { page: this.page.toString() }})
      }
    } catch (err: any) {
      console.error(err)
      this.toastState.showError(`Error loading records: ${err.message}`)
    } finally {
      this.loading = false
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public async onSave(event: CrudSaveEvent): Promise<void> {
    let result: any
    try {
      if (this.save) {
        result = await this.save(event)
      } else {
        if (this.dataModelProjectBased) {
          result = await adminDataService.createOrUpdateData(this.dataModelUri, event.data, this.adminState.activeProjectId)
        } else {
          result = await adminDataService.createOrUpdateData(this.dataModelUri, event.data)
        }
      }
      event.component.actionComplete('Save')
      await this.refreshData()
      this.rowState.setIsDirty(false)
      this.$emit('afterSave', result)
    } catch(err: any) {
      if (err.code === 'E_VALIDATION_FAILURE') {
        event.component.actionComplete('Save', 'Validation failed', err.messages.errors)
      } else {
        console.error(JSON.stringify(err))
        event.component.actionComplete('Save', err.messages?.errors || err.message)
      }
    }
  }

  public onAddNew(): void {
    const data = { id: 0 }
    this.pageData.unshift(data)
    this.$emit('onAddNew', { newData: data, pageData: this.pageData })
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public onCancel(event: any): void {
    if (event.isNew) {
      this.pageData.shift()
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public async onDelete(event: any): Promise<void> {
    try {
      console.log('delete')
      await adminDataService.deleteData(this.dataModelUri, event.data.id)
      event.component.actionComplete('Delete')
      await this.refreshData()
    } catch (err: any) {
      event.component.actionComplete('Delete', err.message)
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public async onDrag(event: any): Promise<void> {
    console.log('event', event)
    this.$emit('itemOrderUpdated', { data: this.pageData })
  }

  public async onModeChange(mode: 'edit' | 'normal'): Promise<void> {
    if (mode === 'edit') {
      this.numEditing += 1
      this.rowState.setCanEdit(false)
    } else {
      this.numEditing -= 1
      this.rowState.setCanEdit(true)
    }
    this.reorderDisabled = this.numEditing > 0
  }
}
