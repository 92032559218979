var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal", attrs: { tabindex: "-1", id: _vm.id } },
    [
      _c("div", { class: ["modal-dialog"] }, [
        _c("div", { staticClass: "modal-content" }, [
          _vm._m(0),
          _c("div", { staticClass: "modal-body" }, [
            _c("div", { staticClass: "row" }, [
              _c("label", { staticClass: "col-3 col-form-label" }, [
                _vm._v("Project Name")
              ]),
              _c("div", { staticClass: "col-9" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.projectName,
                      expression: "projectName"
                    }
                  ],
                  staticClass: "form-control form-control-sm",
                  attrs: { type: "text" },
                  domProps: { value: _vm.projectName },
                  on: {
                    change: _vm.updateProjectCode,
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.projectName = $event.target.value
                    }
                  }
                })
              ])
            ]),
            _c("div", { staticClass: "row" }, [
              _c("label", { staticClass: "col-3 col-form-label" }, [
                _vm._v("Project Code")
              ]),
              _c("div", { staticClass: "col-9" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.projectCode,
                      expression: "projectCode"
                    }
                  ],
                  staticClass: "form-control form-control-sm",
                  attrs: { type: "text" },
                  domProps: { value: _vm.projectCode },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.projectCode = $event.target.value
                    }
                  }
                })
              ])
            ])
          ]),
          _c("div", { staticClass: "modal-footer" }, [
            _vm._m(1),
            _c(
              "button",
              {
                staticClass: "btn btn-sm btn-primary",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.save()
                  }
                }
              },
              [
                !_vm.saving
                  ? _c("span", [
                      _c("i", { staticClass: "bi-save me-1" }),
                      _vm._v(" Save")
                    ])
                  : _c(
                      "div",
                      {
                        staticClass: "spinner-border spinner-border-sm",
                        attrs: { role: "status" }
                      },
                      [
                        _c("span", { staticClass: "visually-hidden" }, [
                          _vm._v("Saving...")
                        ])
                      ]
                    )
              ]
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h5", { staticClass: "modal-title" }, [_vm._v("Add an Project")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "btn btn-sm btn-secondary",
        attrs: { type: "button", "data-bs-dismiss": "modal", title: "Close" }
      },
      [_c("i", { staticClass: "bi-x-lg me-1" }), _vm._v(" Cancel ")]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }