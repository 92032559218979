import { PaginationResult } from '@/types'
import ApiError from '@/util/ApiError'
import { BaseHttpService } from './base-http-service'

class AdminDataService extends BaseHttpService {
  private getAdminUiApiUri(modelName: string, projectId?: number): string {
    if (modelName.startsWith('/')) {
      return `/api${modelName}}`
    } else {
      if (projectId) {
        return `/api/projects/${projectId}/ui/${modelName}`
      } else {
        return `/api/ui/${modelName}`
      }
    }
  }

  public async getDataPage(modelName: string, orderBy: string|string[], pageNum = 1, pageSize?: number, qs?: URLSearchParams, include?: string[], projectId?: number): Promise<PaginationResult<any>> {
    const params = qs || new URLSearchParams()
    params.set('page', pageNum.toString())
    if (Array.isArray(orderBy)) {
      for (const ob of orderBy) {
        params.append('order_by', ob)
      }
    } else {
      params.set('order_by', orderBy)
    }
    if (pageSize) {
      params.set('page_size', pageSize.toString())
    }
    if (include?.length) {
      for(const inc of include) {
        params.append('include', inc)
      }
    }
    const uri = `${this.getAdminUiApiUri(modelName, projectId)}?${params.toString()}`
    const response = await this.doApiGet<PaginationResult<any>>(uri)

    if (response.success) {
      if (response.response) {
        response.response.meta.totalPages = Math.ceil(response.response.meta.total / response.response.meta.perPage)
        return response.response
      }
      return this.getEmptyPaginationResult(pageSize)
    }
    this.handleError(new Error(response.error?.message || 'Request failed but no error sent'))
    return this.getEmptyPaginationResult(pageSize)
  }

  public async getData<T>(modelName: string, orderBy?: string, qs?: URLSearchParams, projectId?: number): Promise<T[]> {
    const params = qs || new URLSearchParams()
    if (orderBy) {
      params.set('order_by', orderBy)
    }
    const uri = `${this.getAdminUiApiUri(modelName, projectId)}/get-all?${params.toString()}`
    const response = await this.doApiGet<T[]>(uri)

    if (response.success) {
      if (response.response) {
        return response.response
      }
      return []
    }
    this.handleError(new Error(response.error?.message || 'Request failed but no error sent'))
    return []
  }

  public async createData<T>(modelName: string, data: T, projectId?: number): Promise<T|undefined> {
    const uri = this.getAdminUiApiUri(modelName, projectId)
    const response = await this.doApiPost<T>(uri, JSON.stringify(data))
    if (response.success) {
      return response.response
    }
    this.handleError(new ApiError(response.error?.messages, response.error?.code, response.error?.message || 'Request failed but no error sent'))
  }

  public async updateData<T extends { id?: number }>(modelName: string, data: T, projectId?: number): Promise<T|undefined> {
    const uri = `${this.getAdminUiApiUri(modelName, projectId)}/${data.id}`
    const response = await this.doApiPut<T>(uri, JSON.stringify(data))
    if (response.success) {
      return response.response
    }
    this.handleError(new ApiError(response.error?.messages, response.error?.code, response.error?.message || 'Request failed but no error sent'))
  }

  public async createOrUpdateData<T extends { id?: number }>(modelName: string, data: T, projectId?: number) {
    if (data.id) {
      return this.updateData(modelName, data, projectId)
    } else {
      return this.createData(modelName, data, projectId)
    }
  }

  public async deleteData(modelName: string, id: number, projectId?: number): Promise<boolean> {
    const uri = `${this.getAdminUiApiUri(modelName, projectId)}/${id}`
    const response = await this.doApiDelete(uri)
    if (response.success) {
      return true
    }
    this.handleError(new ApiError(response.error?.messages, response.error?.code, response.error?.message || 'Request failed but no error sent'))
    return false
  }
}

export default new AdminDataService()
