







































import { Component, Prop, Vue } from 'vue-property-decorator'
import EditableList from '@/components/EditableList.vue'
import ToastState from '@/store/modules/toast'
import { getModule } from 'vuex-module-decorators'
import AdminState from '@/store/modules/admin'
import { ProjectContract } from '@/types/dtos'
import { CrudSaveEvent } from '@/types'
import adminDataService from '@/services/admin-data-service'
import projectService from '@/services/project-service'

@Component({
  components: {
    EditableList,
  }
})
export default class Projects extends Vue {
  @Prop({default: 'projects'})
  uri?: string

  adminState: AdminState = getModule(AdminState)
  toastState: ToastState = getModule(ToastState)

  goToProject(project: ProjectContract): void {
    this.adminState.selectProject(project.id)
    this.$router.push({ path: '/project-admin', query: { projectId: project.id.toString() } })
  }

  public async onProjectSave(event: CrudSaveEvent): Promise<ProjectContract> {
    let result: any
    const project = event.data
    if (project?.id) {
      result = await adminDataService.createOrUpdateData('projects', project)
    } else {
      result = await projectService.create(project)
    }
    return result
  }
}
