












import { ToastData } from '@/types'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Toast as BootstrapToast } from 'bootstrap'
import ToastState from '@/store/modules/toast'
import { getModule } from 'vuex-module-decorators'

@Component({})
export default class Toast extends Vue {

  toastState: ToastState = getModule(ToastState)

  @Prop({ required: true })
  public data!: ToastData

  mounted(): void {
    const el = document.getElementById(this.data.id)
    if (el) {
      el.addEventListener('hidden.bs.toast', () => {
        this.toastState.removeToast(this.data.id)
      })
      const toast = BootstrapToast.getOrCreateInstance(el)
      toast.show()
    } else {
      console.warn('No element found to create toast')
    }
  }
}
