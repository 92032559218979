var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("editable-list", {
        attrs: {
          dataModelUri: "projects",
          uri: _vm.uri,
          sortProperty: "projectName",
          listTitle: "Projects",
          pageSize: 100,
          dataModelProjectBased: false,
          save: _vm.onProjectSave
        },
        scopedSlots: _vm._u([
          {
            key: "row-header-content",
            fn: function() {
              return [
                _c("div", { staticClass: "col fw-bold" }, [_vm._v("Code")]),
                _c("div", { staticClass: "col fw-bold" }, [_vm._v("Name")]),
                _c("div", { staticClass: "col-1" })
              ]
            },
            proxy: true
          },
          {
            key: "row-content",
            fn: function(slotProps) {
              return [
                !slotProps.loading
                  ? [
                      _c(
                        "div",
                        { staticClass: "col input-group has-validation" },
                        [
                          !slotProps.isEditing
                            ? _c("span", { staticClass: "prop-display" }, [
                                _vm._v(_vm._s(slotProps.data.projectCode))
                              ])
                            : _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: slotProps.data.projectCode,
                                    expression: "slotProps.data.projectCode"
                                  }
                                ],
                                staticClass:
                                  "form-control form-control-sm me-1",
                                attrs: { type: "text", required: "" },
                                domProps: { value: slotProps.data.projectCode },
                                on: {
                                  change: function($event) {
                                    return slotProps.setDirty()
                                  },
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      slotProps.data,
                                      "projectCode",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                          _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v("This field is required.")
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "col input-group has-validation" },
                        [
                          !slotProps.isEditing
                            ? _c("span", { staticClass: "prop-display" }, [
                                _vm._v(_vm._s(slotProps.data.projectName))
                              ])
                            : _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: slotProps.data.projectName,
                                    expression: "slotProps.data.projectName"
                                  }
                                ],
                                staticClass:
                                  "form-control form-control-sm me-1",
                                attrs: { type: "text", required: "" },
                                domProps: { value: slotProps.data.projectName },
                                on: {
                                  change: function($event) {
                                    return slotProps.setDirty()
                                  },
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      slotProps.data,
                                      "projectName",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                          _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v("This field is required.")
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "col-1 custom-actions" },
                        [
                          _c(
                            "transition",
                            { attrs: { name: "flip", mode: "out-in" } },
                            [
                              !slotProps.isEditing
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-sm btn-primary",
                                      attrs: {
                                        title: "Manage",
                                        "data-bs-toggle": "tooltip",
                                        type: "button"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.goToProject(slotProps.data)
                                        }
                                      }
                                    },
                                    [_c("i", { staticClass: "bi-gear-fill" })]
                                  )
                                : _vm._e()
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  : [
                      _c("span", { staticClass: "placeholder col" }),
                      _c("span", { staticClass: "placeholder col" }),
                      _c("span", { staticClass: "placeholder col" })
                    ]
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }