





































import { Component, Vue } from 'vue-property-decorator'
import { Modal } from 'bootstrap'
import { v4 as uuidv4 } from 'uuid'
import kebabCase from 'lodash/kebabCase'
import ToastState from '@/store/modules/toast'
import { getModule } from 'vuex-module-decorators'
import projectService from '@/services/project-service'


@Component({})
export default class AddProjectDialog extends Vue {
  toastState: ToastState = getModule(ToastState)
  id: string = uuidv4()
  modal?: Modal
  projectCode = ''
  projectName = ''
  saving = false

  mounted(): void {
    const el = document.getElementById(this.id)
    el!.addEventListener('hidden.bs.modal', () => {
      this.modal?.dispose()
      delete this.modal
      this.projectCode = ''
      this.projectName = ''
    })
  }

  public updateProjectCode(): void {
    if (!this.projectCode) {
      this.projectCode = kebabCase(this.projectName)
    }
  }

  public async save(): Promise<void> {
    try {
      this.saving = true
      await projectService.create({ projectCode: this.projectCode, projectName: this.projectName })
      this.toastState.showSuccess('Save successful')
      this.modal?.hide()
      this.$emit('addProjectSaved')
    } catch (err: any) {
      console.error(err)
      this.toastState.showError(`Save failed: ${err.message || ''}`)
    } finally {
      this.saving = false
    }
  }

  public show(): void {
    const el = document.getElementById(this.id)
    this.modal = new Modal(el!)
    this.modal.show()
  }
}
