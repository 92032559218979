var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.$auth.isAuthenticated,
          expression: "$auth.isAuthenticated"
        }
      ],
      staticClass: "nav-item dropdown ms-1 company-menu"
    },
    [
      _c(
        "a",
        {
          staticClass: "dropdown-toggle btn btn-outline-secondary",
          attrs: {
            href: "#",
            id: "companyMenuDropdown",
            role: "button",
            "data-bs-toggle": "dropdown",
            "aria-expanded": "false"
          }
        },
        [_vm._v(" {Current Company} ")]
      ),
      _c(
        "ul",
        {
          staticClass: "dropdown-menu dropdown-menu-end",
          attrs: { "aria-labelledby": "companyMenuDropdown" }
        },
        [
          _c("li", [
            _c("p", { staticClass: "dropdown-header" }, [
              _vm._v(_vm._s("Current Company"))
            ])
          ]),
          _vm._m(0),
          _vm._m(1)
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [_c("hr", { staticClass: "dropdown-divider" })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c(
        "a",
        {
          staticClass: "dropdown-item disabled d-flex justify-content-between",
          attrs: { href: "#", title: "Switch Company" }
        },
        [_vm._v(" Switch Company ")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }