var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal", attrs: { id: _vm.id } }, [
    _c("div", { staticClass: "modal-dialog" }, [
      _c("div", { staticClass: "modal-content" }, [
        _c("div", { staticClass: "modal-header" }, [
          _vm.header
            ? _c("h5", { staticClass: "modal-title" }, [
                _vm._v(_vm._s(_vm.header))
              ])
            : _vm._e(),
          _c("button", {
            staticClass: "btn-close",
            attrs: {
              type: "button",
              "data-bs-dismiss": "modal",
              "aria-label": "Close"
            }
          })
        ]),
        _c("div", { staticClass: "modal-body" }, [
          _c("p", [_vm._v(_vm._s(_vm.message))])
        ]),
        _c("div", { staticClass: "modal-footer" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-secondary",
              attrs: { type: "button", "data-bs-dismiss": "modal" }
            },
            [_vm._v(_vm._s(_vm.cancelText))]
          ),
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: { type: "button" },
              on: { click: _vm.confirm }
            },
            [_vm._v(_vm._s(_vm.confirmText))]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }